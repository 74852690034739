import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const Critica = ({ data }) => {
  const { t } = useTranslation()
  const { language } = useI18next()

  const [texto, setTexto] = useState("")

  useEffect(() => {
    if (language === "es") {
      setTexto("criticaespanol")
    } else if (language === "ca") {
      setTexto("criticacatalan")
    } else if (language === "en") {
      setTexto("criticaingles")
    } else if (language === "fr") {
      setTexto("criticafrances")
    }
  }, [language])

  const criticas = data.criticas.nodes
  const schema = {
    "@context": t("SEO.critica.schema.context"),
    "@type": t("SEO.critica.schema.type"),
    name: t("SEO.critica.schema.name"),
    description: t("SEO.critica.schema.description"),
    url: t("SEO.critica.schema.url"),
    image: t("SEO.critica.schema.image"),
    sameAs: t("SEO.critica.schema.sameAs"),
    jobTitle: t("SEO.critica.schema.jobTitle"),
  }

  const datos = {
    title: t("SEO.critica.datos.title"),
    description: t("SEO.critica.datos.description"),
    lang: language,
  }

  return (
    <Layout schema={schema} datos={datos}>
      <section className="container max-w-7xl mt-10 sm:mt-16 px-4 sm:px-10">
        <h1 className="border-t border-b text-center py-4 text-3xl sm:text-4xl uppercase">
          {t("critica")}
        </h1>
        <StaticImage
          src="../images/imagencriticas.png"
          className="w-full h-32 sm:h-48"
          alt="Pablo Derqui"
        />
        {criticas.map((critica, index) => (
          <article
            key={index}
            className="flex flex-col items-center justify-center mt-8 border-b border-gris pb-7"
          >
            <cite className="text-blanco text-2xl sm:text-3xl text-center mb-2">
              {critica[texto]}
            </cite>
            <p className="text-gris text-sm text-center">{critica.autor}</p>
          </article>
        ))}
      </section>
    </Layout>
  )
}

export default Critica

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    criticas: allStrapiCritica {
      nodes {
        autor
        criticacatalan
        criticaespanol
        criticafrances
        criticaingles
      }
    }
  }
`
